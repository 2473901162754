import React, { Component } from "react";
import { PnmacText, theme } from 'pnmac-universal-components'
import { genericStatusCheck } from '../utils'
class Footer extends Component {
    
    state = {
        statedis: false
    }
    render() {
        let { translations = {}, matches, layout } = this.props
        let stat = layout?.selectedLoanAvailability?.status
        const BankruptcyCheck = () => {
            let BKStatus =
            {
                activeBk7: genericStatusCheck(stat, "bankruptcy", ["bankruptcyChapter7"])?.hasSubStatus,
                activeBk11: genericStatusCheck(stat, "bankruptcy", ["bankruptcyChapter11"])?.hasSubStatus,
                activeBk12: genericStatusCheck(stat, "bankruptcy", ["bankruptcyChapter12"])?.hasSubStatus,
                activeBk13: genericStatusCheck(stat, "bankruptcy", ["bankruptcyChapter13"])?.hasSubStatus,
                dischargedBk7: genericStatusCheck(stat, "bankruptcy", ["bankruptcyChapter7Discharged"])?.hasSubStatus,
                completeBk7: genericStatusCheck(stat, "bankruptcy", ["bankruptcyChapter7DischargedSurrender"])?.hasSubStatus
            }

            return Object.keys(BKStatus).map((s, i) => {

                if (BKStatus[s] != undefined) {

                    switch (s) {
                        case 'activeBk7':
                            return <div key={i} style={{ color: "white" }}><PnmacText style={{ color: "white", lineHeight: '16pt' }}>{translations?.bankruptcyChapter7}</PnmacText></div>
                            break;
                        case 'activeBk11':
                            return <div key={i} style={{ color: "white" }}><PnmacText style={{ color: "white", lineHeight: '16pt' }}>{translations?.bankruptcyChapter11}</PnmacText></div>
                            break;
                        case 'activeBk12':
                            return <div key={i} style={{ color: "white" }}><PnmacText style={{ color: "white", lineHeight: '16pt' }}>{translations?.bankruptcyChapter12}</PnmacText></div>
                            break;
                        case 'activeBk13':
                            return <div key={i} style={{ color: "white" }}><PnmacText style={{ color: "white", lineHeight: '16pt' }}>{translations?.bankruptcyChapter13}</PnmacText></div>
                            break;
                        case 'dischargedBk7':
                            return <div key={i} style={{ color: "white" }}><PnmacText style={{ color: "white", lineHeight: '16pt' }}>{translations?.bankruptcyChapter7Discharged}</PnmacText></div>
                            break;
                        case 'completeBk7':
                            return <div key={i} style={{ color: "white" }}><PnmacText style={{ color: "white", lineHeight: '16pt' }}>{translations?.bankruptcyChapter7DischargedSurrender}</PnmacText></div>
                            break;
                        default:

                    }

                }
            })

        }

        const { textStyle } = theme;

        return (

            this.props.registration ? (
                <footer role="contentinfo" style={{ backgroundColor: "white", color: "black", paddingBottom: 50 }}>
                    <div style={{maxWidth: 1000, margin: "auto", padding: 20, width: matches.small ? 350 : 1000 }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            
                            <img style={{ width: 35, height: 35 }} src={require('../assets/equal-housing-logo.png')} />
                            <div style={{ width: 15.3 }}/>
                            <div className="col-xs-12 footer-container">
                                <PnmacText style={{
                                    fontFamily: "HalenoirCompactText-Medium",
                                    fontSize: 11,
                                    color: "#4B4B4B"
                                }}>
                                    <span dangerouslySetInnerHTML={{ __html: translations.EqualHousingLegal?.replace("[Year]", `${new Date().getFullYear()}`) }}/>
                                    {" "}
                                    <a style={{ color: "#4B4B4B" }}
                                        href="https://nmlsconsumeraccess.org/"
                                        rel="nofollow"
                                        role="link"
                                        target="_blank"
                                    >
                                        www.nmlsconsumeraccess.org.
                                    </a>
                                    {" "}
                                    {translations.EqualHousingTradeLegal}
                                </PnmacText>                             

                                <PnmacText numberOfLines={3} style={{ marginTop: 10 }} >
                                    <a style={textStyle.clickableLinkMedium} target="_blank" href="https://www.pennymac.com/forms">Forms</a>{" | "}
                                    <a style={textStyle.clickableLinkMedium} target="_blank" href="https://www.pennymac.com/legal">Legal</a>{" | "}
                                    <a style={textStyle.clickableLinkMedium} target="_blank" href="https://www.pennymac.com/privacy">Privacy</a>{" | "}
                                    <a style={textStyle.clickableLinkMedium} target="_blank" href="https://www.pennymac.com/privacy-policy-california-residents">Do
                                    Not Sell or Share My Personal Information</a>{" | "}
                                    <a style={textStyle.clickableLinkMedium} target="_blank" href="https://www.pennymac.com/notice-at-collection-california-residents">Notice at
                                    Collection for CA Residents</a>
                                </PnmacText>                                
                            </div>
                        </div>           
                    
                        <a style={styles.disclosureContainerRegistration} onClick={() => this.setState({statedis: !this.state.statedis})}>                            
                            <PnmacText 
                                style={{...textStyle.clickableLinkMedium, marginTop: 10}}
                            >
                                <img alt="Chevron right" style={styles.chevronContainer} src={require("../assets/chevron-down.png")} /> State disclosures 
                            </PnmacText>
                        </a>                                            

                        <p>
                            {this.state.statedis ? 
                            
                            <PnmacText 
                                style={styles.disclosureContainer}
                            >
                                <div style={styles.disclosureText} dangerouslySetInnerHTML={{__html: translations.StateDisclosuresFooterweb2.replace("[Date]", `${new Date().getFullYear()}`)}}/> 
                            </PnmacText>
                            
                            
                            : null}
                        </p>             
                    </div>
                </footer>
            ) : (
                    <footer style={{ backgroundColor: "#003087", color: "white" }}>
                        <div
                        >
                            <div style={{ maxWidth: "1140px", margin: "auto", padding: 20 }}>
                                <div

                                >
                                    <div>
                                        <div style={{ display: "flex", flexDirection: "row", fontSize: 15 }}>
                                            <div>
                                                <img
                                                    alt="Equal Housing Lender Logo"
                                                    aria-hidden="true"
                                                    className="float-left mr-2"
                                                    height="64"
                                                    src="/images/equal-housing-lender.svg"
                                                    width="60"

                                                />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 10, lineHeight: '18pt' }}>
                                                <small> <div dangerouslySetInnerHTML={{ __html: translations.EqualHousingLegal?.replace("[Year]", `${new Date().getFullYear()}`) }} />
                                                    <a style={{ color: "white" }}
                                                       href="https://nmlsconsumeraccess.org/"
                                                       rel="nofollow"
                                                       role="link"
                                                       target="_blank"
                                                    > www.nmlsconsumeraccess.org.</a>
                                                    <br /> <div dangerouslySetInnerHTML={{ __html: translations.EqualHousingTradeLegal }} /></small>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: matches.small ? "column" : "row",
                                            justifyContent: "space-between",
                                            width: matches.small ? '100%' : '90%',
                                            alignItems: "center",
                                            marginTop: 40,
                                            color:'white'
                                        }}>
                                            <div>
                                                <p style={{ color: "white", fontSize: 15 }}>
                                                    <a
                                                        style={{ color: "white" }}
                                                        href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/35953"
                                                        rel="nofollow"
                                                        role="link"
                                                        target="_blank"
                                                    >
                                                        NMLS Consumer Access
                                                    </a>{" "}
                                                    <span>|</span>{" "}

                                                    <a
                                                        style={{ color: "white" }}
                                                        href="http://www.pennymac.com/privacy"
                                                        rel="nofollow"
                                                        role="link"
                                                        target="_blank"
                                                    >
                                                        Privacy
                                                    </a>{" "}
                                                    <span>|</span>{" "}

                                                    <a
                                                        style={{ color: "white" }}
                                                        href="http://www.pennymac.com/forms"
                                                        rel="nofollow"
                                                        role="link"
                                                        target="_blank"
                                                    >
                                                        Forms
                                                    </a>{" "}
                                                    <span>|</span>{" "}

                                                    <a
                                                        style={{ color: "white" }}
                                                        href="http://www.pennymac.com/legal"
                                                        rel="nofollow"
                                                        role="link"
                                                        target="_blank"
                                                    >
                                                        Legal
                                                    </a>{" "}
                                                    <span> |</span>{" "}
                                                    <a
                                                        style={{ color: "white" }}
                                                        href="https://www.pennymac.com/privacy-policy-california-residents#preferences"
                                                        rel="nofollow"
                                                        role="link"
                                                        target="_blank"
                                                    >
                                                       Do Not Sell or Share My Personal Information
                                                    </a>{" "}
                                                    <span>|</span>{" "}

                                                    <a
                                                        style={{ color: "white" }}
                                                        href="https://www.pennymac.com/notice-at-collection-california-residents"
                                                        rel="nofollow"
                                                        role="link"
                                                        target="_blank"
                                                    >
                                                        Notice at Collection for CA Residents
                                                    </a>
                                                </p>


                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ marginRight: 15 }}>
                                                    <a href="https://itunes.apple.com/us/app/pennymac-mobile/id1349950272?mt=8">
                                                        <img alt="Icon to download on the Apple app store" width="110" height="32" src={require("../assets/app-store.png")} /></a>
                                                </div>
                                                <div>
                                                    <a href="https://play.google.com/store/apps/details?id=com.pennymac.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                                        <img alt="Icon to download on the Google app store" width="110" height="32" src={require("../assets/google-play.png")} /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ lineHeight: '18pt' }}>

                                            <small><div>{BankruptcyCheck()}</div>
                                                <div dangerouslySetInnerHTML={{ __html: translations.StateDisclosuresFooterweb1?.replace("[Date]", `(01-${new Date().getFullYear()})`) }} />
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>)
        );
    }
}

export default Footer

const styles = {
    disclosureContainerRegistration: { cursor: "pointer", dislpay: "flex", flexDirection: "row", marginTop: 10 },
    chevronContainer: { height: 6, width: 11, marginLeft: 12.6 },
    disclosureContainer: {...theme.textStyle.clickableLinkMedium, marginTop: 10, textDecoration: "none", textDecorationLine: "none" },
    disclosureText: {...theme.textStyle.clickableLinkMedium, textDecoration: "none", textDecorationLine: "none" }
}